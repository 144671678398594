import { useContext } from '@nuxtjs/composition-api'
import { Validation } from '@vuelidate/core'
import { email, required, helpers, sameAs } from '@vuelidate/validators'
import { postalCodeRegex, isEmail } from '~/lib/utilities/regex'
import { CountryCode } from '~/types/common'
import { LoginParams } from '~/types/user'

export const isCountryCodeNeedToCheck = (code: string) => {
  return ([CountryCode.NL, CountryCode.BE] as string[]).includes(code)
}

const { withMessage, withAsync } = helpers

export const useValidations = () => {
  const { app } = useContext()

  const checkPassword = async (params: LoginParams) => {
    if (!params.email.trim() || !params.password.trim()) return

    const response = await app.$api.auth.login(params)
    return response.data
  }

  const checkEmail = async (email: string) => {
    if (!(email && isEmail(email))) return false
    return await app.$api.auth.checkEmail(email)
  }

  const checkEmailNoRegister = async (email: string) => {
    if (!(email && isEmail(email))) return false
    const result = await app.$api.auth.checkEmail(email)
    return !result
  }

  const availablePhone = async (phoneNumber: string) => {
    // Note: pass validate when field don't required
    if (!phoneNumber) return true
    return await app.$api.customer.validatePhone(phoneNumber)
  }

  // validation rules
  const password = helpers.regex(/^(?=.*[\d])(?=.*[a-zA-Z]).{8,}$/)
  const houseNumber = helpers.regex(/^[0-9]+$/)
  const rating = helpers.regex(/^[1-5]$/)
  const postalCode = helpers.regex(postalCodeRegex.others)

  // validators
  const validators = {
    required: withMessage(() => `${app.i18n.t('validate.required')}`, required),
    email: withMessage(() => `${app.i18n.t('validate.email')}`, email),
    checkEmail: withMessage(
      () => `${app.i18n.t('validate.emailExists')}`,
      withAsync(checkEmail)
    ),
    checkEmailNoRegister: withMessage(
      () => `${app.i18n.t('validate.unregisteredEmail')}`,
      withAsync(checkEmailNoRegister)
    ),
    checkEmailWhenGuest: (isLoginUser: any, email: any) =>
      withMessage(
        () => `${app.i18n.t('validate.emailExists')}`,
        withAsync(async () => {
          if (!isLoginUser.value) {
            const isValid = await checkEmail(email.value)
            return !!isValid
          } else {
            return true
          }
        })
      ),
    postalCode: withMessage(
      () => `${app.i18n.t('validate.postalCode')}`,
      postalCode
    ),
    availablePhone: withMessage(
      () => `${app.i18n.t('validate.availablePhone')}`,
      withAsync(availablePhone)
    ),
    password: withMessage(() => `${app.i18n.t('validate.password')}`, password),
    checkOldPassword: (email: any, oldPassword: any) =>
      withMessage(
        () => `${app.i18n.t('validate.oldPasswordCheck')}`,
        withAsync(async () => {
          const isValid = await checkPassword({
            email: email.value,
            password: oldPassword.value,
          })
          return !!isValid
        })
      ),
    confirmPassword: (password: any) =>
      withMessage(
        () => `${app.i18n.t('validate.confirmPassword')}`,
        sameAs(password)
      ),
    comparePassword: (oldPassword: any, newPassword: any) =>
      withMessage(
        () => `${app.i18n.t('validate.compareOldAndNewPasswords')}`,
        () => {
          return oldPassword.value !== newPassword.value
        }
      ),
    houseNumber: withMessage(
      () => `${app.i18n.t('validate.houseNumber')}`,
      houseNumber
    ),
    postalCodeWithCountryCode: (countryCode: any, postalCode: any) =>
      withMessage(
        () => `${app.i18n.t('validate.postalCode')}`,
        () => {
          switch (countryCode.value) {
            case CountryCode.NL:
              return postalCodeRegex.NL.test(postalCode.value)
            case CountryCode.BE:
              return postalCodeRegex.BE.test(postalCode.value)
            default:
              return postalCodeRegex.others.test(postalCode.value)
          }
        }
      ),
    houseNumberWithCountryCode: (countryCode: any, houseNumber: any) =>
      withMessage(
        () => `${app.i18n.t('validate.houseNumber')}`,
        () => {
          const houseNumberCode = /^[0-9]+$/
          return isCountryCodeNeedToCheck(countryCode.value)
            ? houseNumberCode.test(houseNumber.value)
            : true
        }
      ),
    rating: withMessage(() => `${app.i18n.t('validate.rating')}`, rating),
  }

  return {
    validators,
  }
}

export type CustomValidations = ReturnType<typeof useValidations>['validators']

export type CustomValidationKeys = keyof CustomValidations

export type RuleValidation =
  | CustomValidationKeys
  | {
      name: CustomValidationKeys
      validator: CustomValidations[CustomValidationKeys]
    }

export type CustomRules<T extends Record<string, any>> = Partial<
  Record<keyof T, RuleValidation[]>
>

export type CombinedValidations = Validation & CustomValidations
